import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import IndustryList from 'components/content-blocks/industry-list';
import Yoast from 'components/globals/yoast';
import { HeroDefaultPage } from 'components/hero/default-page';

import './styles/industries.scss';

export default function Industries() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "industries" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              default_page_hero {
                title
                sub_title
                background_image {
                  source_url
                }
                copy
              }
              industry_list {
                categories {
                  name
                  industries {
                    title
                    url
                    icon {
                      alt_text
                      source_url
                    }
                  }
                }
              }
            }
          }
        }
      }
      allWordpressAcfOptions {
        edges {
          node {
            options {
              awards {
                awards {
                  award {
                    source_url
                  }
                }
                background_image_desktop {
                  url {
                    source_url
                  }
                }
                background_image_mobile {
                  url {
                    source_url
                  }
                }
                content
                sub_title
                title
                main_image_desktop {
                  url {
                    source_url
                  }
                }
                main_image_mobile {
                  url {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const heroContent = data.allWordpressPage.edges[0].node.acf.default_page_hero;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const industryList = data.allWordpressPage.edges[0].node.acf.industry_list;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="industry-select-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultPage { ...heroContent } imageBeforeText />
        <IndustryList { ...industryList } />
      </div>
    </Layout>
  );
}
