
import Link from 'components/link/link';

import './styles/industry-list.scss';

type IndustryListProps = {
  categories: {
    name: string,
    industries: {
      icon: {
        source_url: string,
        alt_text: string,
      },
      title: string,
      url: string,
    }[],
  }[],
};

const IndustryList  = ({ categories }: IndustryListProps) => {

  return (
    <section className="section industry-list-section" data-section="industry list">
      <div className="container">
        {categories.map((category, index) => (
          <div key={ index } className="category-container">
            <h2>{ category.name }</h2>
            <ul className="industries-container">
              {category.industries.map((industry, i) => ( 
                <li key={ i } className="industry-list-item">
                  <Link
                    className="industry-list-link"
                    to={ industry.url }
                  >
                    {industry.icon?.source_url && (
                      <div className="industry-list-icon-container">
                        <img
                          className="industry-list-icon"
                          loading="lazy"
                          src={ industry.icon?.source_url }
                          alt={ industry.icon.alt_text }
                        />
                      </div>
                    )}
                    <div className="industry-list-title">{ industry.title }</div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default IndustryList;
